<template>
  <section class="yml">
    <div class="f mb-20 w">
      <div>
        <h1>NodeJS Setup</h1>
        <p>Инструкция как установить NodeJS</p>
      </div>
      <a class="btn" target="_blank" href="https://nodejs.org/en">Открыть сайт NodeJS</a>
    </div>
    <div class="fields w">
      <div class="form-control">
        <label>IP Address</label>
        <input v-model="ip" />
      </div>
      <div class="form-control">
        <label>Username</label>
        <input spellcheck="false" v-model="username" />
      </div>
    </div>
    <div v-for="(commandNodes, cN) of commands" :key="cN" class="action_result" tabindex="0">
      <template v-for="(node, n) of commandNodes">
        <pre :key="`${n}-${node.type}`" v-if="node.type === 'code'" v-highlightjs="node.content"><code class="bash"></code></pre>
        <p :key="`${n}-${node.type}`" v-if="node.type === 'text'" v-html="node.content" />
      </template>
    </div>
    <div class="row">
      <p class="next">Перейти к <router-link to="/services/mongo_db">настройке MongoDB</router-link></p>
      <p class="next">Перейти к <router-link to="/services/postgre_sql">настройке PostgreSQL</router-link></p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NodeJS',
  data() {
    return {
      ip: '185.185.68.125',
      username: 'yablonev'
    }
  },
  computed: {
    commands() {
      return [
        [
          { type: 'code', content: `ssh ${this.username}@${this.ip}` },
        ],
        [
          { type: 'code', content: 'curl -o- https://raw.githubusercontent.com/nvm-sh/nvm/v0.39.7/install.sh | bash' },
          { type: 'text', content: 'Ставим nvm для nodejs' },
        ],
        [
          { type: 'text', content: 'Далее, чтобы заработал nvm, нужно перезапустить сервер' },
          { type: 'code', content: 'sudo reboot' },
        ],
        [
          { type: 'code', content: `ssh ${this.username}@${this.ip}` },
        ],
        [
          { type: 'code', content: 'nvm install 20' },
        ],
        [
          { type: 'code', content: 'sudo apt install npm' },
          { type: 'text', content: 'И нажать кнопку <span style="color: #62f3d8">«y»</span>' },
        ],
        [
          { type: 'code', content: 'npm i -g pm2' },
        ],
      ]
    }
  },
  methods: {
    async getIP() {
      const data = await fetch('https://api.ipify.org?format=json')
      const json = await data.json()
      return json.ip
    }
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/pages/nginx'

.action_result:focus, .action_result:focus-visible
  box-shadow: inset 0 0 8px 2px #000000c2
  border: 0

.action_result p
  color: #4dbf90
  font-size: 14px
  line-height: 28px
  padding-left: 0.3em

p.next
  display: flex

  a
    color: #55657e
    padding-left: 4px
    font-weight: 500
</style>